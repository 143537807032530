/* global firebase */
import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ResponsiveLine } from "@nivo/line";

import Header from "./Header";
import LineChart from "./LineChart";
import ItemsCountChart from "./ItemsCountChart";
import { store, LOGOUT } from "./appContext";
import { stats, refresh } from "./functions";

const Charts = ({ data }) => {
  return [
    <Row>
      <Col style={{ height: 250 }}>
        <ItemsCountChart key="count" read={data.read} unread={data.unread} />
      </Col>
    </Row>,
    <Row>
      <Col className="text-center">
        <h3>Unread</h3>
        <LineChart key="unread" data={data.unread} />
      </Col>
      <Col className="text-center">
        <h3>Read</h3>
        <LineChart key="read" data={data.read} />
      </Col>
    </Row>
  ];
};

const Home = () => {
  const { state } = useContext(store);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);

  const load = useCallback(() => {
    stats({ user: state.user })
      .then(({ data }) => setData(data))
      .catch(e => console.error({ e }))
      .finally(setLoading(false));
  }, [state]);

  useEffect(() => {
    load();
  }, [load]);

  const refeshCallback = useCallback(() => {
    setLoading(true);
    refresh({ user: state.user })
      .then(load)
      .catch(e => {
        console.error({ e });
        setLoading(false);
      });
  }, [state, load]);

  return (
    <Container className="fixed-top">
      <Header />
      {data ? (
        [
          <Row>
            <Col className="text-right">
              <Button
                variant="outline-primary"
                disabled={isLoading}
                onClick={!isLoading ? refeshCallback : null}
                style={{ margin: 20 }}
              >
                {isLoading ? "Refreshing…" : "Refresh"}
              </Button>
            </Col>
          </Row>,
          <Charts data={data} />
        ]
      ) : (
        <Row>
          <Col className="text-center">
            <Spinner
              animation="border"
              variant="primary"
              style={{ margin: 50 }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Home;
