/*global firebase */
import React, { createContext, useReducer } from "react";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

const initialState = {
  user: localStorage.getItem("user")
};

export const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      const { user } = payload;
      localStorage.setItem("user", user);
      return { ...state, user: user };
    case LOGOUT:
      localStorage.removeItem("request_token");
      localStorage.removeItem("user");
      return {
        ...state,
        user: null
      };
    default:
      console.error(`Unkown action: ${type}`);
      throw new Error();
  }
};

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
