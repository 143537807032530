import React, { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";

import { getRequestToken } from "./functions";

const Login = () => {
  const [error, setError] = useState(false);
  const login = useCallback(() => {
    getRequestToken(window.location.href)
      .then(({ data }) => {
        localStorage.setItem("request_token", data.requestToken);
        window.location.href = data.redirectUrl;
      })
      .catch(e => {
        console.error({ e });
        setError(true);
      });
  }, []);
  return (
    <div>
      <div>
        <Button onClick={login}>Login</Button>
      </div>
      {error && <span>Something went wrong</span>}
    </div>
  );
};

export default Login;
