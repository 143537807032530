import React, { useEffect, useState, useContext } from "react";
import Spinner from "react-bootstrap/Spinner";

import Login from "./Login";
import Home from "./Home";
import Authenticate from "./Authenticate";
import { store } from "./appContext";
import "./App.css";

const App = () => {
  const { state } = useContext(store);
  let comp;
  if (state.user) {
    comp = <Home />;
  } else if (localStorage.getItem("request_token")) {
    comp = <Authenticate />;
  } else {
    comp = <Login />;
  }

  return <div className="App">{comp}</div>;
};

export default App;
