import React, { useMemo } from "react";
import { ResponsiveLine } from "@nivo/line";

const itemsMap = ({ date, count }) => {
  return {
    x: date,
    y: count
  };
};

const LineChart = ({ data }) => {
  const graphData = useMemo(() => data.map(itemsMap), [data]);
  return (
    <ResponsiveLine
      height={250}
      data={[{ id: "", data: graphData }]}
      yScale={{
        min: "0",
        type: "linear"
      }}
      xScale={{
        type: "time",
        format: "%Q",
        precision: "day"
      }}
      axisBottom={{
        format: "%d/%m",
        tickValues: "every 2 days"
      }}
      curve={"stepAfter"}
      margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
      axisLeft={{
        orient: "left",
        tickRotation: 0,
        legend: "items",
        legendOffset: -40,
        legendPosition: "middle",
        format: "d"
      }}
      colors={{ scheme: "paired" }}
      enableSlices={"x"}
      pointSize={10}
      pointColor={"#fff"}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
    />
  );
};

export default LineChart;
