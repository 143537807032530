import React, { useEffect, useContext } from "react";
import Spinner from "react-bootstrap/Spinner";

import { login } from "./functions";
import { store, LOGIN } from "./appContext";

const Authenticate = () => {
  const { dispatch } = useContext(store);

  useEffect(() => {
    const requestToken = localStorage.getItem("request_token");
    login(requestToken)
      .then(({ data }) => {
        console.log({ data });
        dispatch({
          type: LOGIN,
          payload: { user: data }
        });
      })
      .catch(e => {
        console.error({ e });
        localStorage.removeItem("request_token");
      });
  }, []);
  return (
    <div>
      <div>
        <Spinner animation="border" variant="primary" />
      </div>
      <span>Logging in...</span>
    </div>
  );
};

export default Authenticate;
