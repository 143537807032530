import React, { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";

import { dateToStr } from "./utils";

const parseRead = data => {
  let prev = data[0].count;
  let last;
  let res = {};
  for (let i = 1; i < data.length; i++) {
    const day = dateToStr(data[i].date);
    const value = {
      day,
      date: data[i].date,
      read: data[i].count - prev
    };
    res = {
      ...res,
      [day]: value
    };
    prev = data[i].count;
    last = value;
  }
  const today = dateToStr(new Date());
  if (last.day !== today) {
    res = {
      ...res,
      [today]: {
        day: today,
        date: new Date(),
        read: 0
      }
    };
  }
  return res;
};

const parseAdded = (data, read) => {
  let prev = data[0].count;
  let last;
  let res = {};
  for (let i = 1; i < data.length; i++) {
    const day = dateToStr(data[i].date);
    let added = data[i].count - prev + ((read[day] && read[day].read) || 0);
    if (added < 0 && read[day]) {
      read[day].read -= added;
      added = 0;
    }
    const value = {
      day,
      date: data[i].date,
      added
    };
    res = {
      ...res,
      [day]: value
    };
    prev = data[i].count;
    last = value;
  }
  const today = dateToStr(new Date());
  if (last.day !== today) {
    res = {
      ...res,
      [today]: {
        day: today,
        date: new Date(),
        added: 0
      }
    };
  }
  return res;
};

const merge = (obj1, obj2) => {
  var out = {};
  for (let attrname in obj1) {
    out[attrname] = obj1[attrname];
  }
  for (let attrname in obj2) {
    out[attrname] = { ...out[attrname], ...obj2[attrname] };
  }
  return out;
};

const buildData = (read, unread) => {
  const readData = parseRead(read);
  const addedData = parseAdded(unread, readData);
  const data = merge(readData, addedData);
  return Object.values(data).sort((itemA, itemB) => itemA.date - itemB.date);
};

const ItemsCountChart = ({ read, unread }) => {
  const data = useMemo(() => buildData(read, unread), [read, unread]);
  return (
    <ResponsiveBar
      data={data}
      keys={["read", "added"]}
      indexBy="day"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      axisBottom={{
        legend: "day",
        legendPosition: "middle",
        legendOffset: 32
      }}
      colors={{ scheme: "paired" }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20
        }
      ]}
    />
  );
};

export default ItemsCountChart;
