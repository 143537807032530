import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";

import { store, LOGOUT } from "./appContext";

const Header = () => {
  const { dispatch, state } = useContext(store);

  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  return (
    <Navbar bg="primary" variant="dark" className="navbar-fixed-top">
      <Navbar.Brand>Pocket Stats</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>Logged in as:</Navbar.Text>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">{state.user}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={logout}>logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
